
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

import CsAfterSales from "@/components/1crm/sales/cs/cs.vue";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    CsAfterSales,
  },
  props: {
    widgetClasses: String,
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH_USER);
    setCurrentPageBreadcrumbs("CS & After Sales", ["Data"]);
  },

});
